/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.reason-dropdown .ant-select-dropdown-menu-vertical {
  padding: 0;
  max-height: none;
}
.reason-group .ant-select-dropdown-menu-item-group-title {
  background: #e8e8e8;
  color: #8c8c8c;
  font-size: 14px;
}
