/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.claim-actions {
  max-width: 1200px;
  margin: 0 auto 48px;
  padding: 24px 36px;
  border: 1px solid #14837B;
  border-radius: 4px;
}
.mobile .claim-actions {
  padding: 12px 16px;
}
.claim-action-pane {
  padding-top: 16px;
  border-top: 1px dashed #d9d9d9;
  zoom: 1;
}
.claim-action-pane::before,
.claim-action-pane::after {
  display: table;
  content: '';
}
.claim-action-pane::after {
  clear: both;
}
.claim-action {
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 2px;
}
.claim-action:last-child {
  margin-right: 0;
}
.claim-action.claim-download {
  float: right;
  margin-left: 4px;
}
.claim-action .claim-action-btn {
  display: inline-block;
  min-width: 120px;
}
.claim-status-detail {
  font-family: 'Prompt', Helvetica, Arial, sans-serif;
  text-align: center;
  margin-bottom: 16px;
}
.claim-status-detail .claim-no {
  font-size: 1.1em;
  margin-bottom: 12px;
  text-decoration: underline;
  text-align: right;
}
.claim-status-detail .title {
  font-size: 2em;
  color: #14837B;
}
.claim-status-detail .description {
  margin-top: 8px;
}
.claim-info {
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  flex-wrap: nowrap;
}
.claim-info .claim-info-col {
  flex-grow: 1;
  min-width: 0;
  text-align: center;
}
.claim-info .claim-info-col label {
  display: inline-block;
  color: #14837B;
  margin-right: 4px;
}
.claim-info .claim-info-col .claim-info-val {
  display: inline-block;
}
